body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  font-family: 'Inconsolata', monospace;
}
.center{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 440px;
  height: 300px;
  max-width:100%;
  max-height:100%;
  overflow:auto;
}

a:hover {
  color: rgba(0, 0, 0, 0.3);
}
a {
  text-decoration: none;
}

.header {
  padding: 25px 50px;
  position: absolute;
  text-decoration: none;
}

.gradient {
  background-image: linear-gradient( 135deg, #EE9AE5 10%, #5961f9 100%);
}

.gradient-blog {
  background-image: linear-gradient( 135deg, #FAD7A1 10%, #E96D71 100%);
}

.gradient-resume {
  background-image: linear-gradient( 135deg, rgb(66, 185, 201) 10%, rgb(4, 104, 197) 100%);
}

.gradient-nav {
  background-image: linear-gradient( 45deg, #EE9AE5 11%, #5961F9 130%);
}

.css404 {
  font-size: 10em;
  text-align:center;
  display:block;
  position: relative;
  padding-bottom: 0;
}
.cssSoon {
  font-size: 8em;
  text-align:center;
  display:block;
  position: relative;
  padding-bottom: 0;
}

.pageNotFound {
  padding-top: 0;
  text-align: center;
  display: block;
  position: relative;
}

.img-shadow {
  box-shadow: 0 30px 40px 0 rgba(16,36,94,.2);
}

.proj-padding {
  padding-bottom: 30px;
}

.resume-styles {
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  background: #fff;
  margin-top: 50px;
  max-width: 48%;
}



.desc-left {
  float: left;
}
.myFooter{
  background-color: #3498DB;
  color: #fff;
  padding: 50px 0 40px 0;
  margin-top: 60px;
  box-shadow: 0 -2px 8px rgba(0,0,0,0.3);
}
.resume-right{
  float: right;
}

.nav-link{
  color: #fff;
  text-decoration: none !important;
}

.nav-link:hover {
  color: rgba(0, 0, 0, 0.3);
}

.nav-link:focus {
  color: rgba(0, 0, 0, 0.3);
}

.navbar {
  border-radius: 0;
}

.sidenav-link:hover {
  background: rgba(152, 121, 241, 0.99);
  color: #fff;
}

